import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Pressable, TouchableOpacity, FlatList, StyleSheet, ScrollView, SafeAreaView, Modal, Dimensions, Platform } from 'react-native';
import DatePicker from 'react-datepicker';
import axios from 'axios';
// Import the datepicker CSS first, then our custom styles
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/calendar-styles.css';  // Create this file for the calendar styles
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import Constants from 'expo-constants';
import { useUser } from './UserContext';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusSearchContainer = ({ onSearch, bookSummary, handleBookSummaryChange }) => {
    const { origin, destination, travelDate, promoCode, useRewardPoints } = bookSummary;
    const [originState, setOriginState] = useState('');
    const [destinationState, setDestinationState] = useState('');
    const [cities, setCities] = useState([]);
    const [originSuggestions, setOriginSuggestions] = useState([]);
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);
    const [showOriginModal, setShowOriginModal] = useState(false);
    const [showDestinationModal, setShowDestinationModal] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [originError, setOriginError] = useState(false);
    const [destinationError, setDestinationError] = useState(false);
    const [quickDates, setQuickDates] = useState(getNext7Days());
    const { user, userPoints } = useUser();
    const [promoError, setPromoError] = useState('');
    const [isValidatingPromo, setIsValidatingPromo] = useState(false);
    const [validatedPromo, setValidatedPromo] = useState(null);

    const { width: windowWidth } = Dimensions.get('window');
    const [isMobile, setIsMobile] = useState(windowWidth < 768);

    const originSearchRef = useRef(null);
    const destinationSearchRef = useRef(null);

    useEffect(() => {
        if (showOriginModal && originSearchRef.current) {
            setTimeout(() => {
                originSearchRef.current.focus();
            }, 100);
        }
    }, [showOriginModal]);

    useEffect(() => {
        if (showDestinationModal && destinationSearchRef.current) {
            setTimeout(() => {
                destinationSearchRef.current.focus();
            }, 100);
        }
    }, [showDestinationModal]);

    function getNext7Days() {
        const dates = [];
        for (let i = 0; i < 20; i++) {
            dates.push(new Date(new Date().setDate(new Date().getDate() + i)));
        }
        return dates;
    }

    useEffect(() => {
        const loadCities = async () => {
            try {
                const response = await axios.get(API_URL + "/city/loadallPopularCities", {
                    headers: {
                        'x-api-token': API_TOKEN
                    }
                });
                setCities(response.data);
                setOriginSuggestions(response.data);
                setDestinationSuggestions(response.data);
            } catch (error) {
                console.error('Error fetching city suggestions:', error);
            }
        };

        if (cities.length === 0) {
            loadCities();
        }
    }, []);


    const loadSuggestions = async(text, isOrigin) => {
        try {
            if (text.length > 0) {
                const response = await axios.get(API_URL + "/city/search/"+text, {
                    headers: {
                        'x-api-token': API_TOKEN
                    }
                });

                if (isOrigin) {
                    setOriginSuggestions(response.data);
                } else {
                    setDestinationSuggestions(response.data);
                }
            } else {
                if (isOrigin) {
                    setOriginSuggestions(cities);
                } else {
                    setDestinationSuggestions(cities);
                }
            }
        } catch (error) {
            console.error('Error fetching city suggestions:', error);
        }
    };

    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            marginTop: 16,
            width: '100%',
        },
        searchContainer: {
            backgroundColor: 'white',
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#E5E7EB',
            padding: 16,
            width: '90%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 3,
        },
        cityContainer: {
            marginBottom: 12,
            width: isMobile ? '80%' : '45%',
        },
        inputContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#F9FAFB',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#E5E7EB',
        },
        inputIcon: {
            paddingLeft: 12,
        },
        cityTypeText: {
            fontSize: 12,
            fontWeight: '600',
            marginBottom: 6,
            color: '#4B5563',
            textTransform: 'uppercase',
            letterSpacing: 0.5,
        },
        input: {
            flex: 1,
            fontSize: 12,
            paddingVertical: 8,
            paddingHorizontal: 12,
            color: '#111827',
        },
        inputError: {
            borderColor: '#EF4444',
            backgroundColor: '#FEF2F2',
        },
        swapButton: {
            width: 40,
            height: 40,
            backgroundColor: '#F3F4F6',
            borderRadius: 20,
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 10,
        },
        calendarDateContainer: {
            marginBottom: 12,
            width: '100%',
        },
        quickDateContainer: {
            flexDirection: 'row',
            backgroundColor: '#F9FAFB',
            borderRadius: 8,
            padding: 8,
            borderWidth: 1,
            borderColor: '#E5E7EB',
        },
        quickDateButton: {
            padding: 8,
            margin: 4,
            backgroundColor: 'white',
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 60,
            borderWidth: 1,
            borderColor: '#E5E7EB',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.05,
            shadowRadius: 2,
            elevation: 1,
        },
        selectedDateButton: {
            backgroundColor: '#5EBC67',
            borderColor: '#5EBC67',
        },
        monthText: {
            fontSize: 12,
            color: '#6B7280',
            marginBottom: 2,
        },
        dateText: {
            fontSize: 12,
            fontWeight: '600',
            color: '#111827',
        },
        selectedDateText: {
            color: 'white',
        },
        calendarContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
        },
        rewardPointsContainer: {
            width: '100%',
            marginBottom: 12,
            padding: 12,
            backgroundColor: '#F3F4F6',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#E5E7EB',
        },
        rewardPointsRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        checkboxContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        checkbox: {
            width: 20,
            height: 20,
            borderRadius: 6,
            borderWidth: 2,
            borderColor: '#5EBC67',
            marginRight: 8,
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkboxChecked: {
            backgroundColor: '#5EBC67',
        },
        rewardPointsText: {
            fontSize: 10,
            fontWeight: '600',
            color: '#374151',
        },
        pointsAvailable: {
            fontSize: 10,
            color: '#6B7280',
            fontStyle: 'italic',
        },
        searchButtonContainer: {
            width: '100%',
            alignItems: 'center',
            marginTop: 16,
        },
        submitButton: {
            backgroundColor: '#5EBC67',
            paddingVertical: 12,
            paddingHorizontal: 36,
            borderRadius: 8,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 2,
        },
        submitButtonText: {
            color: 'white',
            fontWeight: '600',
            fontSize: 12,
            textAlign: 'center',
        },
        errorText: {
            color: '#EF4444',
            fontSize: 10,
            marginTop: 4,
            fontWeight: '500',
        },
        successText: {
            color: '#10B981',
            fontSize: 10,
            marginTop: 4,
            fontWeight: '500',
        },
        asterisk: {
            color: '#EF4444',
        },
        modalOverlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'flex-start', // Changed from 'flex-end' to 'flex-start'
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        modalContent: {
            backgroundColor: 'white',
            borderRadius: 0, // Remove border radius for full screen
            height: '100%', // Take full height
            width: '100%',
            padding: 20,
        },
        calendarModal: {
            minHeight: 'auto',
            height: 'auto', // Allow calendar modal to be shorter
            paddingBottom: 24,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
        },
        /*
        modalContent: {
            backgroundColor: 'white',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            padding: 20,
            width: '100%',
            alignItems: 'center',
        }, */
        modalHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
            paddingBottom: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#eee',
        },
        modalTitle: {
            fontSize: 14,
            fontWeight: '600',
            color: '#333',
        },
        searchInputContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 10,
            paddingHorizontal: 15,
            marginBottom: 15,
        },
        searchIcon: {
            marginRight: 10,
        },
        modalInput: {
            flex: 1,
            paddingVertical: 12,
            paddingHorizontal: 12,
            fontSize: 12,
            color: '#333',
        },
        cityList: {
            paddingHorizontal: 5,
        },
        cityItem: {
            paddingVertical: 12,
            paddingHorizontal: 16,
            borderBottomWidth: 1,
            borderBottomColor: '#eee',
        },
        cityItemContent: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
        },
        cityTextContainer: {
            marginLeft: 10,
            flex: 1,
        },
        cityNameText: {
            fontSize: 12,
            fontWeight: '500',
            color: '#333',
        },
        stateNameText: {
            fontSize: 10,
            color: '#666',
            marginTop: 2,
        },
    });

    const handleOriginFocus = () => {
        setShowOriginModal(true);
    };

    const handleDestinationFocus = () => {
        setShowDestinationModal(true);
    };

    const handleOriginSelect = (item) => {
        const updatedBookSummary = { ...bookSummary, origin: item.cityName };
        handleBookSummaryChange(updatedBookSummary);
        setOriginState(item.state);
        setShowOriginModal(false);
        setOriginError(false);
    };

    const handleDestinationSelect = (item) => {
        const updatedBookSummary = { ...bookSummary, destination: item.cityName };
        handleBookSummaryChange(updatedBookSummary);
        setDestinationState(item.state);
        setShowDestinationModal(false);
        setDestinationError(false);
    };

    const handleSwap = () => {
        const updatedBookSummary = {
            ...bookSummary,
            origin: destination,
            destination: origin,
        };
        handleBookSummaryChange(updatedBookSummary);
        setOriginState(destinationState);
        setDestinationState(originState);
    };

    const handleDateChange = (selectedDate) => {
        const updatedBookSummary = { ...bookSummary, travelDate: selectedDate };
        handleBookSummaryChange(updatedBookSummary);
        setShowCalendar(false);
    };

    const handleCityChange = (text, isFromCity) => {
        const updatedBookSummary = { ...bookSummary };
        if (isFromCity) {
            updatedBookSummary.origin = text;
        } else {
            updatedBookSummary.destination = text;
        }
        handleBookSummaryChange(updatedBookSummary);
        loadSuggestions(text, isFromCity);
    };

    const handleRewardPointsToggle = () => {
        const updatedBookSummary = {
            ...bookSummary,
            useRewardPoints: !bookSummary.useRewardPoints,
            promoCode: !bookSummary.useRewardPoints ? '' : bookSummary.promoCode,
            validatedPromo: !bookSummary.useRewardPoints ? null : bookSummary.validatedPromo
        };
        handleBookSummaryChange(updatedBookSummary);
        if (!bookSummary.useRewardPoints) {
            setPromoError('');
            setValidatedPromo(null);
        }
    };

    const validatePromoCode = async () => {
        if (!bookSummary.promoCode) {
            return true;
        }

        setIsValidatingPromo(true);
        setPromoError('');

        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd')
            const response = await axios.post(
                `${API_URL}/offer/validate`,
                {
                    offerCode: bookSummary.promoCode,
                    isLoggedIn: !!user,
                    userId: user?.userId,
                    from: bookSummary.origin,
                    to: bookSummary.destination,
                    travelDate: formattedDate,
                    bookingAmount: 0,
                    paymentMethod: "CREDIT_CARD"
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-token': API_TOKEN
                    }
                }
            );

            if (response.data.valid) {
                setValidatedPromo(response.data);
                const updatedBookSummary = {
                    ...bookSummary,
                    validatedPromo: response.data,
                };
                handleBookSummaryChange(updatedBookSummary);
                return true;
            } else {
                setPromoError(response.data.messages[0] || 'Invalid promo code');
                return false;
            }
        } catch (error) {
            console.error('Promo validation error:', error);
            setPromoError(error.response?.data?.messages?.[0] || 'Error validating promo code');
            return false;
        } finally {
            setIsValidatingPromo(false);
        }
    };

    const searchAvailabilities = async () => {
        if (!origin || !destination) {
            if (!origin) {
                setOriginError(true);
            } else {
                setOriginError(false);
            }
            if (!destination) {
                setDestinationError(true);
            } else {
                setDestinationError(false);
            }
            return;
        }

        try {
            setOriginError(false);
            setDestinationError(false);

            if (bookSummary.promoCode && !bookSummary.useRewardPoints) {
                const isPromoValid = await validatePromoCode();
                if (!isPromoValid) {
                    return;
                }
            }

            onSearch();
        } catch (error) {
            throw error;
        }
    };


    return (
        <View style={styles.container}>
            <View style={styles.searchContainer}>
                <View style={styles.cityContainer}>
                    <Text style={styles.cityTypeText}>FROM <Text style={styles.asterisk}> *</Text></Text>
                    <View style={styles.inputContainer}>
                        <MaterialIcons name="location-on" size={20} color="#5EBC67" style={styles.inputIcon} />
                        <TextInput
                            style={styles.input}
                            placeholder="FROM"
                            value={origin}
                            onChangeText={(text) => handleCityChange(text, true)}
                            onPointerDown={() => setShowOriginModal(true)}
                        />
                    </View>
                    {originError && (
                        <Text style={styles.errorText}>Please type a city</Text>
                    )}
                    {originState && (
                        <Text style={styles.cityTypeText}>{originState}</Text>
                    )}
                </View>

                <Pressable style={styles.swapButton} onPress={handleSwap}>
                    <MaterialIcons name="swap-horiz" size={24} color="black" />
                </Pressable>

                <View style={styles.cityContainer}>
                    <Text style={styles.cityTypeText}>TO<Text style={styles.asterisk}> *</Text></Text>
                    <View style={styles.inputContainer}>
                        <MaterialIcons name="location-on" size={20} color="#5EBC67" style={styles.inputIcon} />
                        <TextInput
                            style={styles.input}
                            placeholder="TO"
                            value={destination}
                            onChangeText={(text) => handleCityChange(text, false)}
                            onPointerDown={() => setShowDestinationModal(true)}
                        />
                    </View>
                    {destinationError && (
                        <Text style={styles.errorText}>Please type a city</Text>
                    )}
                    {destinationState && (
                        <Text style={styles.cityTypeText}>{destinationState}</Text>
                    )}
                </View>

                <View style={styles.calendarDateContainer}>
                    <Text style={styles.cityTypeText}>ON</Text>
                    <ScrollView 
                        horizontal 
                        showsHorizontalScrollIndicator={false}
                        contentContainerStyle={styles.quickDateContainer}
                    >
                        {quickDates.map((date, index) => (
                            <TouchableOpacity
                                key={index}
                                style={[
                                    styles.quickDateButton,
                                    travelDate.toDateString() === date.toDateString() && styles.selectedDateButton
                                ]}
                                onPress={() => handleDateChange(date)}
                            >
                                <Text style={[
                                    styles.monthText,
                                    travelDate.toDateString() === date.toDateString() && styles.selectedDateText
                                ]}>
                                    {format(date, 'MMM')}
                                </Text>
                                <Text style={[
                                    styles.dateText,
                                    travelDate.toDateString() === date.toDateString() && styles.selectedDateText
                                ]}>
                                    {format(date, 'dd')}
                                </Text>
                                <Text style={[
                                    styles.monthText,  // New style for day
                                    travelDate.toDateString() === date.toDateString() && styles.selectedDateText
                                ]}>
                                    {format(date, 'EEE')}
                                </Text>
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                    <View style={styles.calendarContainer}>
                        <Pressable onPress={() => setShowCalendar(true)}>
                            <View style={styles.calendarContainer}>
                                <Text style={styles.dateText}>{travelDate.toDateString()}</Text>
                                <MaterialIcons name="calendar-today" size={24} color="#5EBC67" />
                            </View>
                        </Pressable>
                    </View>
                </View>

                {user?.userId && userPoints > 0 && (
                    <View style={styles.rewardPointsContainer}>
                        <View style={styles.rewardPointsRow}>
                            <TouchableOpacity
                                style={styles.checkboxContainer}
                                onPress={handleRewardPointsToggle}
                            >
                                <View style={[
                                    styles.checkbox,
                                    bookSummary.useRewardPoints && styles.checkboxChecked
                                ]}>
                                    {bookSummary.useRewardPoints && (
                                        <MaterialIcons name="check" size={16} color="white" />
                                    )}
                                </View>
                                <Text style={styles.rewardPointsText}>
                                    Use Reward Points
                                </Text>
                            </TouchableOpacity>
                            <Text style={styles.pointsAvailable}>
                                {userPoints} points available
                            </Text>
                        </View>
                    </View>
                )}

                {!bookSummary.useRewardPoints && (
                    <View style={styles.cityContainer}>
                        <Text style={styles.cityTypeText}>Promo Code</Text>
                        <View style={styles.inputContainer}>
                            <MaterialIcons name="local-offer" size={20} color="#5EBC67" style={styles.inputIcon} />
                            <TextInput
                                style={[
                                    styles.input,
                                    promoError ? styles.inputError : null
                                ]}
                                placeholder="Enter Promo Code"
                                value={bookSummary.promoCode}
                                onChangeText={(text) => {
                                    handleBookSummaryChange({ ...bookSummary, promoCode: text, validatedPromo: null });
                                    setPromoError('');
                                    setValidatedPromo(null);
                                }}
                            />
                        </View>
                        {promoError && (
                            <Text style={styles.errorText}>{promoError}</Text>
                        )}
                        {validatedPromo && (
                            <Text style={styles.successText}>
                                {validatedPromo.discountType === 'POINTS'
                                    ? `You will earn ${validatedPromo.discountDetails.rewardPointsInfo.pointsToBeEarned} points`
                                    : `Promo Applied`
                                }
                            </Text>
                        )}
                    </View>
                )}

                <View style={styles.searchButtonContainer}>
                    <Pressable onPress={searchAvailabilities} style={styles.submitButton}>
                        <Text style={styles.submitButtonText}>Search</Text>
                    </Pressable>
                </View>
            </View>

            {/* Modal for Origin City */}
            <Modal visible={showOriginModal} transparent={true} animationType="slide" onRequestClose={() => setShowOriginModal(false)}>
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContent}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>Select Origin City</Text>
                            <TouchableOpacity onPress={() => setShowOriginModal(false)}>
                                <MaterialIcons name="close" size={24} color="#333" />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.searchInputContainer}>
                            <MaterialIcons name="search" size={20} color="#666" style={styles.searchIcon} />
                            <TextInput
                                ref={originSearchRef}
                                autoFocus={true}
                                style={styles.modalInput}
                                placeholder="Search Origin City"
                                onChangeText={(text) => loadSuggestions(text, true)}
                                placeholderTextColor="#666"
                            />
                        </View>
                        <FlatList
                            contentContainerStyle={styles.cityList}
                            data={originSuggestions}
                            keyExtractor={(item) => item.cityId}
                            renderItem={({ item }) => (
                                <TouchableOpacity 
                                    style={styles.cityItem} 
                                    onPress={() => handleOriginSelect(item)}
                                >
                                    <View style={styles.cityItemContent}>
                                        <MaterialIcons name="location-on" size={20} color="#5EBC67" />
                                        <View style={styles.cityTextContainer}>
                                            <Text style={styles.cityNameText}>{item.cityName}</Text>
                                            <Text style={styles.stateNameText}>{item.state}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            showsVerticalScrollIndicator={false}
                        />
                    </View>
                </View>
            </Modal>

            {/* Modal for Destination City */}
            <Modal visible={showDestinationModal} transparent={true} animationType="slide" onRequestClose={() => setShowDestinationModal(false)}>
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContent}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>Select Destination City</Text>
                            <TouchableOpacity onPress={() => setShowDestinationModal(false)}>
                                <MaterialIcons name="close" size={24} color="#333" />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.searchInputContainer}>
                            <MaterialIcons name="search" size={20} color="#666" style={styles.searchIcon} />
                            <TextInput
                                ref={destinationSearchRef}
                                autoFocus={true}
                                style={styles.modalInput}
                                placeholder="Search Destination City"
                                onChangeText={(text) => loadSuggestions(text, false)}
                                placeholderTextColor="#666"
                            />
                        </View>
                        <FlatList
                            contentContainerStyle={styles.cityList}
                            data={destinationSuggestions}
                            keyExtractor={(item) => item.cityId}
                            renderItem={({ item }) => (
                                <TouchableOpacity 
                                    style={styles.cityItem} 
                                    onPress={() => handleDestinationSelect(item)}
                                >
                                    <View style={styles.cityItemContent}>
                                        <MaterialIcons name="location-on" size={20} color="#5EBC67" />
                                        <View style={styles.cityTextContainer}>
                                            <Text style={styles.cityNameText}>{item.cityName}</Text>
                                            <Text style={styles.stateNameText}>{item.state}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            showsVerticalScrollIndicator={false}
                        />
                    </View>
                </View>
            </Modal>

            {/* Modal for Calendar */}
            <Modal visible={showCalendar} transparent={true} animationType="slide" onRequestClose={() => setShowCalendar(false)}>
                <View style={styles.modalOverlay}>
                    <View style={[styles.modalContent, styles.calendarModal]}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>Select Travel Date</Text>
                            <TouchableOpacity onPress={() => setShowCalendar(false)}>
                                <MaterialIcons name="close" size={24} color="#333" />
                            </TouchableOpacity>
                        </View>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                            <DatePicker
                                selected={travelDate}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                inline
                                dateFormat="MMMM d, yyyy"
                                showPopperArrow={false}
                            />
                        </div>
                    </View>
                </View>
            </Modal>
        </View>
    );
};



export default BusSearchContainer;